import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-type": "application/json;charset=UTF-8",
  }
});

axiosInstance.interceptors.request.use(
  config => {
    let user = sessionStorage.getItem('username') ?? localStorage.getItem('username');
    let local_id = sessionStorage.getItem('local_id') ?? localStorage.getItem('local_id');
    let authToken = sessionStorage.getItem('token') ?? localStorage.getItem('token');
    if (user) {
      config.headers['Username'] = user;
      config.headers['Local_Id'] = local_id;
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;