import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import logo from '../../assets/logo.png'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl } from '@mui/base'
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { loginAsync, recoverAsync } from '../user/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import InformationModal from '../modals/information';

export function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [error, setError] = useState();
    const [recoverPassword, setRecoverPassword] = useState(false);
    const [information, setInformation] = React.useState({ title: '', body: '', open: false });
    const [loading, setLoading] = useState(false);

    const [rememberMe, setRemember] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const { control, handleSubmit, getValues, formState: { errors } } = useForm({
        defaultValues: {
        }
    });

    const setRecover = () => {
        setRecoverPassword(!recoverPassword)
    }

    const doRecoverPassword = () =>{
        setLoading(true)
        let values = getValues();
        dispatch(recoverAsync(values)).then(x => {
            if (x.payload?.success) {
                setInformation({ title: 'Contraseña restablecida', body: 'Si el email que ingresó existe, se enviará un correo electrónico con su nueva contraseña', open: true })
            }
            else {
                setError("Error en los datos ingresados");
            }
            setLoading(false)
        })
    }

    const doLogin = () => {
        setLoading(true)
        let values = getValues();
        dispatch(loginAsync(values)).then(x => {
            if (x.payload?.username) {
                if (rememberMe) {
                    localStorage.setItem('username', x.payload.username);
                    localStorage.setItem('local_id', x.payload.local_id);
                    localStorage.setItem('token', x.payload.token);
                }
                else {
                    sessionStorage.setItem('username', x.payload.username);
                    sessionStorage.setItem('local_id', x.payload.local_id);
                    sessionStorage.setItem('token', x.payload.token);
                }
                navigate('/home');
            }
            else{
                setError("Error en los datos ingresados");
            }
                setLoading(false)
            
        })
    }

    return (
        <>
            <Box display='grid' justifyItems='center' >
                <Box mt={10}>
                    <img width={'250px'} src={logo}></img>
                </Box>
                <InformationModal onConfirm={() => setInformation({ open: false })} body={information.body} title={information.title} open={information.open}></InformationModal>
                <Box>
                    <Box id='form-control-box' width={400}>
                        {recoverPassword &&
                            <Typography>Ingrese su correo electrónico para restablecer la contraseña</Typography>}

                        <FormControl sx={{ m: 1 }} variant="outlined">
                            <InputLabel sx={{ p: 2 }} htmlFor="outlined-adornment-user">Usuario</InputLabel>
                            <Controller
                                name="username"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <OutlinedInput {...field} fullWidth size="small" />}
                            />
                        </FormControl>

                        {recoverPassword &&
                            <>
                             <Box mt={2}>
                                    {error &&
                                        <Alert severity='error'>Se produjo un error al intentar recuperar la contraseña.</Alert>
                                    }
                                </Box>
                                <Button onClick={setRecover}>Volver</Button>
                                <LoadingButton
                                    sx={{ mt: 2, mb: 2 }}
                                    variant='contained'
                                    loading={loading}
                                    onClick={handleSubmit(doRecoverPassword)}>Restablecer</LoadingButton>
                            </>
                        }
                        {
                            !recoverPassword &&
                            <>
                                <FormControl sx={{ m: 1 }} variant="outlined">
                                    <InputLabel sx={{ p: 2 }} htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <OutlinedInput
                                            {...field}
                                            size='small'
                                            fullWidth
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"

                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />}
                                    />
                                </FormControl>
                                <Box textAlign='right'>
                                    <Link onClick={setRecover}>Olvidé mi contraseña</Link>
                                </Box>
                                <FormControlLabel control={<Checkbox checked={rememberMe} onChange={() => setRemember(!rememberMe)} />} label="Recuérdame" />
                                <Box mt={2}>
                                    {error &&
                                        <Alert severity='error'>El usuario y/o la contraseña ingresada no son correctos.</Alert>
                                    }
                                </Box>
                                <LoadingButton
                                    sx={{ mt: 2, mb: 2 }}
                                    variant='contained'
                                    loading={loading}
                                    onClick={handleSubmit(doLogin)}>Inciar sesión</LoadingButton>
                            </>
                        }
                    </Box>

                </Box>

            </Box>


        </>
    )

}