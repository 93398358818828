import * as React from 'react';
import { Box,  Typography} from '@mui/material';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import SplitButton from '../dropdown';
import { useSelector } from 'react-redux';
import { selectFile } from '../files/fileSlice';
import { DataGrid } from '@mui/x-data-grid';
import CancelButton from '../dropdown/cancelbutton';

export default function Repository() {
    const { history } = useSelector(selectFile);
    const columns = [
        {
            field: 'name',
            headerName: 'Nombre',
            width: 250,
            sortable: false,
        },
        {
            field: 'format',
            headerName: 'Formato',
            sortable: false,
        },
        {
            field: 'processedDate',
            headerName: 'Fecha procesamiento',
            align: 'center',
            width: 250,
            headerAlign: 'center',
            sortable: false,
            renderCell: ((params) => {
                return (
                    params.row.processing ? 'Procesando...':
                    new Date(params.row.processedDate).toLocaleDateString("es-AR")
                )
            }),
        },
        {
            field:'download',
            width: 160,
            headerName: 'Descarga',
            sortable: false,
            renderCell: ((params) => {
                return (
                    params.row.processing ? <CancelButton row={params.row}></CancelButton>:
                    <SplitButton row={params.row}></SplitButton>
                )
            }),
        }
    ];
    return (
        <>
                
            <Typography variant="h1">
                <RestorePageIcon sx={{ height: 20 }} color="secondary" />Historial de archivos
                </Typography>
                <DataGrid
                    disableColumnMenu
                    disableColumnSorting
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    rows={history}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    hideFooter
                />
            
            </>
    );
}
