import { HubConnectionBuilder } from "@microsoft/signalr"
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FileUpload from "../files";
import GridFiles from "../files/grid";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useDispatch, useSelector } from "react-redux";
import { getHistoryAsync, replaceData, resetFile, selectFile, startProcessAsync } from "../files/fileSlice";
import Repository from "../repository";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useAlert from "../alert/useAlert";
import ConfirmationModal from "../modals/confirmation";
import InformationModal from "../modals/information";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";

export default function Home() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [sendEmail, setSendEmail] = React.useState(true);
    const [information, setInformation] = React.useState({ title: '', body: '', open: false });
    const [loading, setLoading] = React.useState(false);
    const { upload, inProgress, progress } = useSelector(selectFile);
    const { setAlert } = useAlert();
    const navigate = useNavigate();
    useEffect(() => {
        checkStatus()
        dispatch(getHistoryAsync())
        const token = localStorage.getItem('token')?? sessionStorage.getItem('token');;
        const expired = isTokenExpired(token);
        if(expired){
                localStorage.clear();
                sessionStorage.clear();
                navigate('/login');
        }
    }, []);

    const isTokenExpired = (token) => {
        if (!token) {
          return true;
        }
      
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; 
      
          return decodedToken.exp < currentTime;
        } catch (error) {
          console.error("Error decoding token:", error);
          return true;
        }
      };

    const checkStatus = async () => {
        try {
            const url = process.env.REACT_APP_API + "progress";
            const conn = new HubConnectionBuilder()
                .withUrl(url)
                .build();

            conn.on("CheckStatus", (data) => {
                console.log(data)
                dispatch(replaceData(data))
                if (!data.inProgress) {
                    dispatch(getHistoryAsync()).then(x => {
                        setLoading(false)
                        setInformation({ title: 'Archivo procesado', body: 'Se procesó el archivo de manera correcta', open: true })
                    })
                }
            });

            await conn.start();
        } catch (error) {
            setAlert('Error al conectar al servidor', 'error');
            console.log("Error al conectar al socket: ", error)
        }
    }

    const doProcesamiento = () => {
        setLoading(true)
        handleClose()
        dispatch(startProcessAsync({upload, sendEmail}))
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const resetForm = () => {
        setLoading(false)
        dispatch(resetFile())
    }

    const changeSendEmail = () => {
        console.log("Change email: ", sendEmail)
        setSendEmail(!sendEmail)
    }

    return (
        <>

            <Box sx={{ p: 2, display: { md: 'grid', lg: 'inline-flex' }, }}>
                <Box sx={{ pt: 2 }} justifyItems='center'>
                    <Typography variant='h1'>
                        <CloudUploadIcon sx={{ height: 20 }} color="secondary" />
                        Subir un archivo</Typography>
                    <FileUpload progress={progress ?? 0} inProgress={inProgress} />
                    <GridFiles />
                    <ConfirmationModal open={open} sendEmail={changeSendEmail} onCancel={handleClose} onConfirm={doProcesamiento} />
                    <InformationModal onConfirm={() => setInformation({ open: false })} open={information.open} title={information.title} body={information.body} />
                    <Box>
                        <Button sx={{ m: 2 }}
                            onClick={resetForm}
                            disabled={loading}
                            variant="outlined"
                        >Restablecer</Button>
                        <LoadingButton
                            onClick={handleClickOpen}
                            endIcon={<PlayCircleFilledWhiteIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            disabled={upload.items.length === 0 || inProgress || loading}>
                            <span>Procesar</span>
                        </LoadingButton>
                    </Box>
                </Box>
                <Divider sx={{ m: 10 }} orientation="vertical" variant="middle" flexItem />
                <Box sx={{ pt: 3, height:'fit-content' }} justifyItems='center'>
                <Repository />
                </Box>
            </Box>
        </>
    )
}