import { createTheme } from "@mui/material";

export const AviraTheme = createTheme({
  palette: {
    button: {
      main: '#388e3c',
      light: '#757ce8',
      dark: '#002884',
      contrastText: '#fff',
    },
    background:{
      boxes:'#fff8de'
    },
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: "bold"
    },
    h2: {
      fontSize: 22,
      fontWeight: "bold"
    },
  },
});