import React from "react";
import { Navigate } from "react-router-dom";
const AuthContext = React.createContext(null);

const useAuth = () => {
    return sessionStorage.getItem('token') ?? localStorage.getItem('token');
};
export const AuthProvider = ({ children }) => {
    const [token, setToken] = React.useState(null);
    const handleLogin = async () => {
        const token = useAuth;
        setToken(token);
    };

    const handleLogout = () => {
        setToken(null);
    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const ProtectedRoute = ({ children }) => {
    const token = useAuth();
    if (!token) {
        return <Navigate to="/login" replace />;
    }
    return children;
};