import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { selectFile, uploadAsync } from "./fileSlice";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../alert/useAlert";
import { UploadTemplate } from "./upload";

export default function FileUpload({ progress, inProgress }) {
    const dispatch = useDispatch();
    const fileTypes = ["CSV", "XLSX"];
    const { upload, fileName } = useSelector(selectFile);
    const [error, setError] = useState();
    const [uploading, setUploading] = useState();
    const { setAlert } = useAlert();

    const handleChange = (file) => {
        setError()
        setUploading(true);
        dispatch(uploadAsync(file)).then(x => {
            if (!x.payload.success)
                setAlert(x.payload.errors[0], 'error');
            setUploading(false);
        })
    };

    return (
        <>
            <FileUploader
                label='Clic aquí o arrastre para subir un documento'
                handleChange={handleChange}
                id='box'
                disabled={inProgress}
                children={<UploadTemplate progress={progress} inProgress={inProgress} extensions={fileTypes.join(", ")} file={upload.path} fileName={fileName} error={error} uploading={uploading} />}
                onTypeError={() => setError('Formato no admitido')}
                name="file"
                types={fileTypes}
            />
        </>

    );
}