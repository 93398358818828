import api from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    upload: {
        items: []
    },
    history: [],
    inProgress: false,
    progress: 0
}

export const uploadAsync = createAsyncThunk(
    "file/uploadAsync",
    async (file, { rejectWithValue }) => {
        const url = `api/Files/Upload`;
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await api.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getHistoryAsync = createAsyncThunk(
    "file/getHistory",
    async (userId, { rejectWithValue }) => {
        const url = `api/Files/History`;
        try {
            const response = await api.get(url);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const startProcessAsync = createAsyncThunk(
    "file/startProcessAsync",
    async (data, { rejectWithValue }) => {
        const url = `api/Files/StartProcess?sendEmail=${data.sendEmail}`;
        try {
            const response = await api.post(url, data.upload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const cancelJobAsync = createAsyncThunk(
    "file/cancelJobAsync",
    async (data, { rejectWithValue }) => {
        const url = `api/Files/CancelJob?jobId=${data.id}`;
        try {
            const response = await api.get(url);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const downloadFileAsync = createAsyncThunk(
    "file/downloadFileAsync",
    async (data, { rejectWithValue }) => {
        const url = `api/Files/?id=${data.id}`;
        try {
            const response = await api.get(url, {
                responseType: "blob"
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {
        resetFile: (state) => {
            state.upload = initialState.upload;
            state.inProgress = false;
            state.progress = 0
        },
        replaceData: (state, action) => {
            if (state.progress < action.payload.progress)
                state.progress = action.payload.progress;
            state.inProgress = action.payload.inProgress;
            state.fileName = action.payload.fileName;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadAsync.fulfilled, (state, action) => {
                if (action.payload.success)
                    state.upload = action.payload
            })
            .addCase(downloadFileAsync.fulfilled, (state) => {
                state.exported = true
            })
            .addCase(getHistoryAsync.fulfilled, (state, action) => {
                state.history = action.payload
            })
    }
});
export const { replaceData, resetFile } = fileSlice.actions;
export default fileSlice.reducer;
export const selectFile = (state) => state.file;