import './App.css';
import Home from './features/home';
import { Route, Routes } from "react-router-dom";
import { Login } from './features/login';
import Header from './features/navbar';
import { ThemeProvider } from '@mui/material';
import { AviraTheme } from './aviraTheme';
import React from 'react';
import { AuthProvider, ProtectedRoute } from './app/authProvider';
import AlertPopup from './features/alert/alertPopup';


function App() {
  return (
    <div className="App">
      <AuthProvider>

        <ThemeProvider theme={AviraTheme}>
          <Header />
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="*/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home"
              element={<ProtectedRoute>
                <Home />
                <AlertPopup/>
              </ProtectedRoute>}
            />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
