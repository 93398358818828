import { useSelector } from "react-redux";
import { selectFile } from "../../files/fileSlice";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, Typography } from "@mui/material";

export default function ConfirmationModal({open,onConfirm, onCancel, sendEmail}) {
    const { upload } = useSelector(selectFile);
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Procesamiento de archivos
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography m={1}>
                    Se verifican <strong>{upload.rows}</strong> cantidad de casos a consultar. Este proceso puede durar unos minutos. ¿Desea continuar?
                    </Typography>
                    <FormControlLabel
                        control={<Switch defaultChecked onChange={sendEmail} color="primary" />}
                        label={<Typography variant="caption">Enviar archivo por mail cuando finalice</Typography>}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button sx={{ m: 2 }}
                    disabled={upload.items.length === 0}
                    variant="contained"
                    onClick={onConfirm}>Confirmar</Button>
            </DialogActions>
        </Dialog>)
}