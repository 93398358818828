import api from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    user :{}
}

export const loginAsync = createAsyncThunk(
    "user/loginAsync",
    async (data, { rejectWithValue }) => {
        const url = `api/users/login`;
        try {
            const response = await api.post(url, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);

export const recoverAsync = createAsyncThunk(
    "user/recoverAsync",
    async (data, { rejectWithValue }) => {
        const url = `api/users/recover`;
        try {
            const response = await api.post(url, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);


export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.user =  action.payload
            })
    }
});
export const selectUser = (state) => state.user;
export default userSlice.reducer;