import { Alert } from '@mui/material';
import useAlert from './useAlert';

const AlertPopup = () => {
    const { text, type } = useAlert();

    if (text && type) {
        return (
            <Alert
                severity={type}
                sx={{
                    position: 'fixed',
                    top: 100,
                    right: 50
                }}
            >
                {text}
            </Alert>
        );
    } else {
        return <></>;
    }
};

export default AlertPopup;