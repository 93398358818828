import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from '@mui/material';

export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();

    if (location.pathname !== '/home') {
        return null;
    }

    const doLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        AVIRA
                    </Typography>
                    <Button color="inherit" onClick={doLogout} endIcon={<LogoutIcon />}>Salir</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

