  
import { configureStore } from '@reduxjs/toolkit';
import userReducer  from '../features/user/userSlice';
import fileReducer  from '../features/files/fileSlice';

const store = configureStore({
    reducer: {
      user : userReducer,
      file : fileReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
  });
  export { store };
  