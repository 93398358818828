import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectFile } from '../fileSlice';
import { Box } from '@mui/material';
export default function GridFiles() {
    const { upload } = useSelector(selectFile);

    const columns = [
        {
            field: 'dni',
            headerName: 'DNI',
            width: 150,
            sortable: false,
        },
        {
            field: 'nombre',
            headerName: 'Nombre y apellido',
            width: 400,
            sortable: false,
        },
        {
            field: 'sexo',
            width: 150,
            headerName: 'Sexo',
            align: 'center',
            headerAlign: 'center',
            sortable: false,
        },
    ];

    const rows = upload?.items ?? []
    return (
        <Box sx={{ height: 450, width: '100%' }}>
            <DataGrid
                disableColumnMenu
                disableColumnSorting
                getRowId={(row) => row.dni}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            />
        </Box>
    );

}