import { Box, CircularProgress, LinearProgress, Skeleton, Typography } from "@mui/material";

export default function CurrentStatus({ progress, fileName }) {
    return (
        <>

            <Box sx={{ width: '100%' }}>
                <Typography variant="body1">Archivo <strong>{fileName}</strong> en proceso - {progress}% completado</Typography>

                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            </Box>
        </>
    );
}