import { Button } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from "react-redux";
import { cancelJobAsync, getHistoryAsync } from "../files/fileSlice";
export default function CancelButton({ row }) {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(cancelJobAsync({ id: row.id })).then(response => {
            if(response.error){
                console.log("Se produjo un error al intentar borrar el documento");
            }else{
                dispatch(getHistoryAsync())
            }
        })
    };
    return (
        <>
         <Button
                    onClick={handleClick}
                    sx={{backgroundColor:'#ef4506' }}
                    startIcon={<CancelIcon />}
                    variant='contained'
                >
                    Cancelar
                </Button>
        </>
    )
}