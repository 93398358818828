import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { Box, Typography } from '@mui/material';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CurrentStatus from '../currentStatus';
import CircularProgress from '@mui/material/CircularProgress';

export const UploadTemplate = ({ progress, inProgress, extensions, file, fileName, error, uploading }) => {
    return (
        <>
            <Box
                id='box-upload'
                sx={{
                    mt: 3,
                    mb: 3,
                    p: { md: 5, xs: 1 },
                    border: '3px dashed',
                    borderColor: 'primary.main',
                    display: 'flex',
                    borderRadius: 2,
                    backgroundColor: '#F9F9F7',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {
                    uploading ?
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Box>
                        :
                        inProgress ? <CurrentStatus fileName={fileName} progress={progress ?? 0} />
                            :
                            error ?
                                <>
                                    <Typography variant='body1'><DangerousRoundedIcon sx={{ height: 16 }} />{error}</Typography>
                                </>
                                :
                                (
                                    file ?
                                        <>
                                            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                <AttachFileIcon />
                                                <Typography variant='h2' color='button.main'>{file.split(/[\\\/]/).pop()}</Typography>
                                            </Box>
                                        </>
                                        :
                                        <>
                                            <UploadFileRoundedIcon />
                                            <Typography variant='body1'>Arrastre un archivo o haga clic aquí para seleccionar</Typography>
                                            <Typography m={2} fontSize={12}>Formatos válidos {extensions}</Typography>
                                        </>
                                )
                }
            </Box>

        </>)
}
