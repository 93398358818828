import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, Typography } from "@mui/material";

export default function InformationModal({ open, onConfirm, title, body }) {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ m: 2 }}
                    variant="contained"
                    onClick={onConfirm}>Aceptar</Button>
            </DialogActions>
        </Dialog>)
}